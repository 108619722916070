import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    amazonTitle: {
        marginBottom: '1rem',
        fontFamily: 'Dancing Script',
        fontSize: '1.8rem',
        fontWeight: 500
    },
    amazon: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        justifyItems: 'center',
        justifyContent: 'center',
        gap: '1rem',        
        '& a': {
            transition: 'opacity 250ms ease-in-out',
            '&:hover': {
                opacity: 0.7
            }
        }
    }
}));

const AmazonWidget = () => {
    const classes = useStyles();

    return (
        <>
            <Typography variant='body1' align='center' color='primary' className={classes.amazonTitle}>Our favorite products</Typography>
            <div className={classes.amazon}>
                <div><a aria-label="15-Minute Fast Fat Blasts DVD" href="https://www.amazon.com/gp/product/B00R3LIB0A?&linkCode=li3&tag=actuleaks-20&linkId=ec6e38513e6beafda9ceb756414f5948&language=en_US&ref_=as_li_ss_il" target="_blank" rel="noreferrer"><img border="0" alt='' src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00R3LIB0A&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=actuleaks-20&language=en_US" /></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=actuleaks-20&language=en_US&l=li3&o=1&a=B00R3LIB0A" width="1" height="1" border="0" alt="" style={{border: 'none !important', margin: '0px !important'}} /></div>
                <div><a aria-label="feeding littles" href="https://www.amazon.com/Feeding-Littles-Beyond-Baby-Led-Weaning-Friendly-Recipes/dp/0593419243?crid=34KBUF0BFU82W&keywords=recipes&qid=1661867543&s=books&sprefix=recipes%2Cstripbooks-intl-ship%2C232&sr=1-1&linkCode=li3&tag=actuleaks-20&linkId=70588871681b00871d806c94e31bdb58&language=en_US&ref_=as_li_ss_il" target="_blank" rel="noreferrer"><img border="0" alt='' src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=0593419243&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=actuleaks-20&language=en_US" /></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=actuleaks-20&language=en_US&l=li3&o=1&a=0593419243" width="1" height="1" border="0" alt="" style={{border: 'none !important', margin: '0px !important'}} /></div>
                <div><a aria-label="self care protect challenge journal" href="https://www.amazon.com/Weekly-Self-Care-Project-Challenge-Journal/dp/0310460166?crid=3BXDH7QPA4P4C&keywords=personal+care&qid=1661867781&s=books&sprefix=personal+care%2Cstripbooks-intl-ship%2C238&sr=1-2&linkCode=li3&tag=actuleaks-20&linkId=45df84332cbaff0849ce929a2586d115&language=en_US&ref_=as_li_ss_il" target="_blank" rel="noreferrer"><img border="0" alt='' src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=0310460166&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=actuleaks-20&language=en_US" /></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=actuleaks-20&language=en_US&l=li3&o=1&a=0310460166" width="1" height="1" border="0" alt="" style={{border: 'none !important', margin: '0px !important'}} /></div>
                <div><a aria-label="revlon one steb dryer volumizer brush" href="https://www.amazon.com/Revlon-One-Step-Dryer-Volumizer-Brush/dp/B01LSUQSB0?qid=1661867725&s=beauty-intl-ship&sr=1-11&linkCode=li3&tag=actuleaks-20&linkId=0a9612d237e4491c594573abea7ba3f0&language=en_US&ref_=as_li_ss_il" target="_blank" rel="noreferrer"><img border="0" alt='' src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B01LSUQSB0&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=actuleaks-20&language=en_US" /></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=actuleaks-20&language=en_US&l=li3&o=1&a=B01LSUQSB0" width="1" height="1" border="0" alt="" style={{border: 'none !important', margin: '0px !important'}} /></div>
                <div><a aria-label="soul care save your life" href="https://www.amazon.com/Soul-Care-Save-Your-Life/dp/1540902137?crid=3BXDH7QPA4P4C&keywords=personal+care&qid=1661867781&s=books&sprefix=personal+care%2Cstripbooks-intl-ship%2C238&sr=1-6&linkCode=li3&tag=actuleaks-20&linkId=b568cf2aa4830e3ac14a3fe541ad06dc&language=en_US&ref_=as_li_ss_il" target="_blank" rel="noreferrer"><img alt='' border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=1540902137&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=actuleaks-20&language=en_US" /></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=actuleaks-20&language=en_US&l=li3&o=1&a=1540902137" width="1" height="1" border="0" alt="" style={{border: 'none !important', margin: '0px !important'}} /></div>
                <div><a aria-label="Naturals vitamin topical hyaluronic" href="https://www.amazon.com/TruSkin-Naturals-Vitamin-Topical-Hyaluronic/dp/B01M4MCUAF?qid=1661867725&s=beauty-intl-ship&sr=1-9&linkCode=li3&tag=actuleaks-20&linkId=f6a77816c81326582519b03d9efbfc2f&language=en_US&ref_=as_li_ss_il" target="_blank" rel="noreferrer"><img border="0" alt='' src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B01M4MCUAF&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=actuleaks-20&language=en_US" /></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=actuleaks-20&language=en_US&l=li3&o=1&a=B01M4MCUAF" width="1" height="1" border="0" alt="" style={{border: 'none !important', margin: '0px !important'}} /></div>
            </div>
        </>
    );
};

export default AmazonWidget;